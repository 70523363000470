<template>
  <div>
    <div class="container">
      <div class="title">
        <span>学习资料</span>
      </div>
    </div>
    <div class="filesTable" style="background-color: #ffffff">
      <el-table
        :data="tableData"
        style="width: 60%;text-align: center;margin: 20px auto"
        max-height="350px"
      >
        <el-table-column
          prop="description"
          label="文件名"
          width="200">
        </el-table-column>
        <el-table-column
          prop="gmtCreate"
          label="上传时间"
          width="200">
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="180">
          <template slot-scope="scope">
            <a :href="scope.row.url" style="text-decoration: none" target="_top">
              <el-button size="small">下载</el-button>
            </a>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="backgroundPic">
      <img
        src="https://cos.mooc.huangzhaokun.top/moocImg/undraw_building_blocks_re_rwls.svg"
        alt="">
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'StudyFiles',
  data () {
    return {
      termId: 0,
      tableData: []
    }
  },
  created () {
    this.termId = this.$route.query.termId
    this.getAllResource()
  },
  methods: {
    getAllResource () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/resource/showResourcesByTermId',
        params: {
          termId: this.termId
        }
      }).then(res => {
        if (res.data.status) {
          this.tableData = res.data.data
        }
      })
    }
  }
}
</script>

<style scoped>
.title {
  font-size: 24px;
  margin: 50px 0 30px 0;
}

.filesTable {
  width: 100%;
  border-radius: 10px;
  padding: 20px 0;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.backgroundPic {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.backgroundPic img {
  width: 400px;
  height: auto;
}
</style>
